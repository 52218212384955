import React, {
  useCallback,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  withRouter,
  Link,
  RouteComponentProps,
} from 'react-router-dom';

import {
  openPurchaseDialog,
} from 'actions/fate';
import {
  isDowngradedSubscription,
} from 'actions/fate/subscriptions';
import {
  closeSidebar,
} from 'actions/sidebar';

import Image from 'components/Image';
import TippyWrapper from 'components/TippyWrapper';

import {
  useAppSelector,
} from 'features/app/store';

import getPremiumDaysRemaining from 'selectors/fate/getPremiumDaysRemaining';

import {
  UIRestriction,
} from 'types/myself';

type Props = RouteComponentProps;

function PlayerFate({
  history,
}: Props) {
  const dispatch = useDispatch();
  const actions = useAppSelector(state => state.actions.actions);
  const currentFate = useAppSelector(state => state.fate.data.currentFate);
  const fateCards = useAppSelector(state => state.fate.data.fateCards);
  const hasSubscription = useAppSelector(state => state.settings.subscriptions.hasBraintreeSubscription);
  const premiumDaysRemaining = useAppSelector(state => Math.max(getPremiumDaysRemaining(state), 0));
  const subscriptionType = useAppSelector(state => state.settings.subscriptions.subscriptionType);
  const showFateUI = useAppSelector(state =>
    !state.myself.uiRestrictions?.find((restriction) => restriction === UIRestriction.Fate)
  );

  const handleClick = useCallback(() => {
    const actionsRemaining = actions;
    const actionItem = fateCards.find(({ action }) => action === 'RefillActions');

    if (actionsRemaining <= 6) {
      dispatch(openPurchaseDialog(actionItem));
    } else {
      history.push('/fate');
    }
  }, [
    actions,
    dispatch,
    fateCards,
    history,
  ]);

  const renderSubscriptionInformation = useCallback(() => {
    const userDidDowngrade = isDowngradedSubscription(hasSubscription, subscriptionType);

    if (subscriptionType === 'ExceptionalFriendship') {
      return (<>
        <div>
          <p>{premiumDaysRemaining} day{premiumDaysRemaining !== 1 && 's'} left of Exceptional Friendship</p>
          <p>
            <Link
              onClick={closeSidebar}
              to='/fate'
              className='enhanced-text--inverse'
            >
              Enhance your membership for more benefits!
            </Link>
          </p>
        </div>
      </>);
    }

    if (userDidDowngrade || subscriptionType === 'EnhancedExceptionalFriendship') {
      return (<>
        <div>
          <p>
            {premiumDaysRemaining} day{premiumDaysRemaining !== 1 && 's'} left
            of <span className='enhanced-text--inverse'>Enhanced Exceptional Friendship</span>
          </p>
        </div>
      </>);
    }

    return (<>
      <div>
        <h3
          style={{
            textTransform: 'uppercase',
          }}
        >
          Be Exceptional!
        </h3>
        <Link
          onClick={closeSidebar}
          to='/fate'
        >
          <p>Subscribe for a second candle and brand new stories every month.</p>
        </Link>
      </div>
    </>);
  }, [
    hasSubscription,
    premiumDaysRemaining,
    subscriptionType,
  ]);

  if (!showFateUI) {
    return null;
  }

  return (
    <li className='item'>
      <TippyWrapper
        tooltipData={{
          description: 'Open the Fate tab',
        }}
      >
        <button
          className='icon--currency sidebar__fate-button sidebar__button--has-focus-outline'
          onClick={handleClick}
          style={{
            padding: 0,
          }}
          tabIndex={0}
          type='button'
        >
          <Image
            alt=''
            className='media__object'
            icon='fate'
            type='currencies'
            width={60}
            height={78}
            style={{
              cursor: 'pointer',
            }}
          />
          <span className='u-visually-hidden'>Open the Fate tab</span>
        </button>
      </TippyWrapper>

      <div className='item__desc'>
        <span className='js-item-name item__name'>Fate</span>
        <div className='item__value'>{currentFate.toLocaleString('en-GB')}</div>
        {renderSubscriptionInformation()}
      </div>
    </li>
  );
}

PlayerFate.displayName = 'PlayerFate';

export default withRouter(PlayerFate);
