export const QUALITY_ID_PENNY = 22390;

export const EXPLICITLY_FILTERABLE_QUALITY_IDS = [
  142591,
  144845,
  144846,
];

export const FILTERABLE_CATEGORY_NAMES = [
  'BasicAbility',
  'SidebarAbility',
  'Menace',
  'Skills',
];

export const UNFILTERABLE_ENHANCEMENT_QUALITY_IDS = [
  18736,
  18737,
  18738,
];

export const EXCLUDED_CATEGORY_NAMES = [
  'Constant Companion',
];

export const EXCLUDED_QUALITY_IDS = [
  QUALITY_ID_PENNY,
];
