export const LOGIN_REQUESTED = 'user/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE';

export const LOGOUT_REQUESTED = 'user/LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'user/LOGOUT_FAILURE';

export const FACEBOOK_LOGIN_REQUESTED = 'user/FACEBOOK_LOGIN_REQUESTED';
export const FACEBOOK_LOGIN_SUCCESS = 'user/FACEBOOK_LOGIN_SUCCESS';
export const FACEBOOK_LOGIN_FAILURE = 'user/FACEBOOK_LOGIN_FAILURE'; // We tried to log in, but the API said no
export const FACEBOOK_LOGIN_ERROR = 'user/FACEBOOK_LOGIN_ERROR'; // Error state

export const TWITTER_LOGIN_REQUESTED = 'user/TWITTER_LOGIN_REQUESTED';
export const TWITTER_LOGIN_SUCCESS = 'user/TWITTER_LOGIN_SUCCESS';
export const TWITTER_LOGIN_FAILURE = 'user/TWITTER_LOGIN_FAILURE';

export const GOOGLE_LOGIN_REQUESTED = 'user/GOOGLE_LOGIN_REQUESTED';
export const GOOGLE_LOGIN_SUCCESS = 'user/GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_ERROR = 'user/GOOGLE_LOGIN_ERROR';
export const GOOGLE_LOGIN_FAILURE = 'user/GOOGLE_LOGIN_FAILURE';

export const ALLOW_LOGIN_FROM_APP_REQUESTED = 'user/ALLOW_LOGIN_FROM_APP_REQUESTED';
export const ALLOW_LOGIN_FROM_APP_SUCCESS = 'user/ALLOW_LOGIN_FROM_APP_SUCCESS';
export const ALLOW_LOGIN_FROM_APP_FAILURE = 'user/ALLOW_LOGIN_FROM_APP_FAILURE';
