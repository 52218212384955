export enum PurchaseOutfitStep {
  Ready,
  Success,
  Failure,
  Loading,
}

export const POSSIBLE_DEFAULT_NAMES = [
  'Casual',
  'Mourning',
  'Work',
  'Fancy',
  'Midnight',
  'Night-attire',
  'Gadding about',
  'Staying in',
  'Public Occasion',
  'Disguise',
  'Court',
  'Protective',
  'Enticing',
];