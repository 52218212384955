export const TOGGLE_NOTIFICATION_DIALOG = 'settings/TOGGLE_NOTIFICATION_DIALOG';

// SETTINGS
export const FETCH_SETTINGS_REQUESTED = 'settings/FETCH_SETTINGS_REQUESTED';
export const FETCH_SETTINGS_SUCCESS = 'settings/FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'settings/FETCH_SETTINGS_FAILURE';

// AUTH METHODS
export const FETCH_AUTH_METHODS_REQUESTED = 'settings/FETCH_AUTH_METHODS_REQUESTED';
export const FETCH_AUTH_METHODS_SUCCESS = 'settings/FETCH_AUTH_METHODS_SUCCESS';
export const FETCH_AUTH_METHODS_FAILURE = 'settings/FETCH_AUTH_METHODS_FAILURE';

// MESSAGE PREFERENCES
export const UPDATE_MESSAGE_PREFERENCES = 'settings/UPDATE_MESSAGE_PREFERENCES';

export const SAVE_MESSAGE_PREFERENCES_REQUESTED = 'settings/SAVE_MESSAGE_PREFERENCES_REQUESTED';
export const SAVE_MESSAGE_PREFERENCES_SUCCESS = 'settings/SAVE_MESSAGE_PREFERENCES_SUCCESS';
export const SAVE_MESSAGE_PREFERENCES_FAILURE = 'settings/SAVE_MESSAGE_PREFERENCES_FAILURE';

// CONTACTS

export const DELETE_CONTACT_REQUESTED = 'settings/DELETE_CONTACT_REQUESTED';
export const DELETE_CONTACT_SUCCESS = 'settings/DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'settings/DELETE_CONTACT_FAILURE';

export const REQUEST_PASSWORD_RESET_REQUESTED = 'settings/REQUEST_PASSWORD_RESET_REQUESTED';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'settings/REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'settings/REQUEST_PASSWORD_RESET_FAILURE';

export const PASSWORD_RESET_REQUESTED = 'settings/PASSWORD_RESET_REQUESTED';
export const PASSWORD_RESET_SUCCESS = 'settings/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'settings/PASSWORD_RESET_FAILURE';

// CHANGE USERNAME
export const TOGGLE_USERNAME_DIALOG = 'settings/TOGGLE_USERNAME_DIALOG';

export const CHANGE_USERNAME_REQUESTED = 'settings/CHANGE_USERNAME_REQUESTED';
export const CHANGE_USERNAME_SUCCESS = 'settings/CHANGE_USERNAME_SUCCESS';
export const CHANGE_USERNAME_FAILURE = 'settings/CHANGE_USERNAME_FAILURE';

// MESSAGES VIA
export const SAVE_MESSAGES_VIA_REQUESTED = 'settings/SAVE_MESSAGES_VIA_REQUESTED';
export const SAVE_MESSAGES_VIA_SUCCESS = 'settings/SAVE_MESSAGES_VIA_SUCCESS';
export const SAVE_MESSAGES_VIA_FAILURE = 'settings/SAVE_MESSAGES_VIA_FAILURE';

export const TOGGLE_DEACTIVATE_DIALOG = 'settings/TOGGLE_DEACTIVATE_DIALOG';

// DEACTIVE ACCOUNT
export const DEACTIVATE_ACCOUNT_REQUESTED = 'settings/DEACTIVATE_ACCOUNT_REQUESTED';
export const DEACTIVATE_ACCOUNT_SUCCESS = 'settings/DEACTIVATE_ACCOUNT_SUCCESS';
export const DEACTIVATE_ACCOUNT_FAILURE = 'settings/DEACTIVATE_ACCOUNT_FAILURE';


export const TOGGLE_EMAIL_LINK_DIALOG = 'settings/TOGGLE_EMAIL_LINK_DIALOG';

// Link Email To ACCOUNT
export const LINK_EMAIL_TO_ACCOUNT_REQUESTED = 'settings/LINK_EMAIL_TO_ACCOUNT_REQUESTED';
export const LINK_EMAIL_TO_ACCOUNT_SUCCESS = 'settings/LINK_EMAIL_TO_ACCOUNT_SUCCESS';
export const LINK_EMAIL_TO_ACCOUNT_FAILURE = 'settings/LINK_EMAIL_TO_ACCOUNT_FAILURE';

export const TOGGLE_EMAIL_UPDATE_DIALOG = 'settings/TOGGLE_EMAIL_UPDATE_DIALOG';

// UPDATE EMAIL ADDRESS
export const UPDATE_EMAIL_REQUESTED = 'settings/UPDATE_EMAIL_REQUESTED';
export const UPDATE_EMAIL_SUCCESS = 'settings/UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILURE = 'settings/UPDATE_EMAIL_FAILURE';

// Verify email address
export const VERIFY_EMAIL_REQUESTED = 'settings/VERIFY_EMAIL_REQUESTED';
export const VERIFY_EMAIL_SUCCESS = 'settings/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'settings/VERIFY_EMAIL_FAILURE';

// Unlink social account
export const UNLINK_SOCIAL_ACCOUNT_REQUESTED = 'settings/UNLINK_SOCIAL_ACCOUNT_REQUESTED';
export const UNLINK_SOCIAL_ACCOUNT_SUCCESS = 'settings/UNLINK_SOCIAL_ACCOUNT_SUCCESS';
export const UNLINK_SOCIAL_ACCOUNT_FAILURE = 'settings/UNLINK_SOCIAL_ACCOUNT_FAILURE';

// Link social account
export const LINK_SOCIAL_ACCOUNT_REQUESTED = 'settings/LINK_SOCIAL_ACCOUNT_REQUESTED';
export const LINK_SOCIAL_ACCOUNT_SUCCESS = 'settings/LINK_SOCIAL_ACCOUNT_SUCCESS';
export const LINK_SOCIAL_ACCOUNT_FAILURE = 'settings/LINK_SOCIAL_ACCOUNT_FAILURE';


export const CLOSE_ALL_MODALS = 'settings/CLOSE_ALL_MODALS';
