import React from 'react';

export default function Underline() {
  return (
    <img
      alt=""
      className="lodgings-subarea__selected-underline"
      src="/img/underline.png"
    />
  );
}