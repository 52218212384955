// Fetch items
export const FETCH_REQUESTED = 'fate/FETCH_REQUESTED';
export const FETCH_SUCCESS = 'fate/FETCH_SUCCESS';
export const FETCH_FAILURE = 'fate/FETCH_FAILURE';

// Change avatar
export const CHANGE_AVATAR_REQUESTED = 'fate/CHANGE_AVATAR_REQUESTED';
export const CHANGE_AVATAR_SUCCESS = 'fate/CHANGE_AVATAR_SUCCESS';
export const CHANGE_AVATAR_FAILURE = 'fate/CHANGE_AVATAR_FAILURE';

// Open / close dialog
export const OPEN_PURCHASE_DIALOG = 'fate/OPEN_PURCHASE_DIALOG';
export const CLOSE_PURCHASE_DIALOG = 'fate/CLOSE_PURCHASE_DIALOG';

// Select an avatar
export const SELECT_AVATAR = 'fate/SELECT_AVATAR';

// Purchase an item
export const PURCHASE_ITEM_REQUESTED = 'fate/PURCHASE_ITEM_REQUESTED';
export const PURCHASE_ITEM_SUCCESS = 'fate/PURCHASE_ITEM_SUCCESS';
export const PURCHASE_ITEM_FAILURE = 'fate/PURCHASE_ITEM_FAILURE';

// Open fate dialog
export const OPEN_FATE_DIALOG = 'fate/OPEN_FATE_DIALOG';

// process fate change
export const PROCESS_FATE_CHANGE = 'fate/PROCESS_FATE_CHANGE';

// Set active subtab on the Fate tab
export const SET_ACTIVE_SUBTAB = 'fate/SET_ACTIVE_SUBTAB';

export const TOGGLE_ENHANCED_STORE = 'fate/TOGGLE_ENHANCED_STORE';
