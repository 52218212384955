// Display/clear access code result
export const DISPLAY_ACCESS_CODE_RESULT = 'accessCode/DISPLAY_ACCESS_CODE_RESULT';
export const CLEAR_ACCESS_CODE_RESULT = 'accessCode/CLEAR_ACCESS_CODE_RESULT';

export const DISPLAY_ACCESS_CODE_CHALLENGE = 'accessCode/DISPLAY_ACCESS_CODE_CHALLENGE';
export const CLEAR_ACCESS_CODE_CHALLENGE = 'accessCode/CLEAR_ACCESS_CODE_CHALLENGE';

export const FLUSH_ACCESS_CODE_STATE = 'accessCode/FLUSH_ACCESS_CODE_STATE';

// Fetch access code
export const FETCH_ACCESS_CODE_REQUESTED = 'accessCode/FETCH_ACCESS_CODE_REQUESTED';
export const FETCH_ACCESS_CODE_SUCCESS = 'accessCode/FETCH_ACCESS_CODE_SUCCESS';
export const FETCH_ACCESS_CODE_FAILURE = 'accessCode/FETCH_ACCESS_CODE_FAILURE';

export const PROCESS_ACCESS_CODE_REQUESTED = 'accessCode/PROCESS_ACCESS_CODE_REQUESTED';
export const PROCESS_ACCESS_CODE_SUCCESS = 'accessCode/PROCESS_ACCESS_CODE_SUCCESS';
export const PROCESS_ACCESS_CODE_FAILURE = 'accessCode/PROCESS_ACCESS_CODE_FAILURE';