export const FETCH_ALL_REQUESTED = 'messages/FETCH_ALL_REQUESTED';
export const FETCH_ALL_SUCCESS = 'messages/FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'messages/FETCH_ALL_FAILURE';

export const FETCH_FEED_MESSAGES_REQUESTED = 'messages/FETCH_FEED_MESSAGES_REQUESTED';
export const FETCH_FEED_MESSAGES_SUCCESS = 'messages/FETCH_FEED_MESSAGES_SUCCESS';
export const FETCH_FEED_MESSAGES_FAILURE = 'messages/FETCH_FEED_MESSAGES_FAILURE';

export const CLEAR_NOTIFICATION = 'messages/CLEAR_NOTIFICATION';
export const FETCH_QUEUED = 'messages/FETCH_QUEUED';
export const FETCH_INTERACTIONS_REQUESTED = 'messages/FETCH_INTERACTIONS_REQUESTED';
export const FETCH_INTERACTIONS_SUCCESS = 'messages/FETCH_INTERACTIONS_SUCCESS';
export const FETCH_INTERACTIONS_FAILURE = 'messages/FETCH_INTERACTIONS_FAILURE';

export const DELETE_REQUESTED = 'messages/DELETE_REQUESTED';
export const DELETE_SUCCESS = 'messages/DELETE_SUCCESS';
export const DELETE_FAILURE = 'messages/DELETE_FAILURE';

export const EMAIL_REQUESTED = 'messages/EMAIL_REQUESTED';
export const EMAIL_SUCCESS = 'messages/EMAIL_SUCCESS';
export const EMAIL_FAILURE = 'messages/EMAIL_FAILURE';

export const ACCEPT_REQUESTED = 'messages/ACCEPT_REQUESTED';
export const ACCEPT_SUCCESS = 'messages/ACCEPT_SUCCESS';
export const ACCEPT_FAILURE = 'messages/ACCEPT_FAILURE';

export const REJECT_REQUESTED = 'messages/REJECT_REQUESTED';
export const REJECT_SUCCESS = 'messages/REJECT_SUCCESS';
export const REJECT_FAILURE = 'messages/REJECT_FAILURE';

export const CANCEL_REQUESTED = 'messages/CANCEL_REQUESTED';
export const CANCEL_SUCCESS = 'messages/CANCEL_SUCCESS';
export const CANCEL_FAILURE = 'messages/CANCEL_FAILURE';

export const CLEAR_CACHE = 'messages/CLEAR_CACHE';

export const CLOSE_DIALOG = 'messages/CLOSE_DIALOG';
