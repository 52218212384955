// Fetch plans
export const FETCH_PLANS_REQUESTED = 'plans/FETCH_PLANS_REQUESTED';
export const FETCH_PLANS_SUCCESS = 'plans/FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAILURE = 'plans/FETCH_PLANS_FAILURE';

// Toggle edit
export const TOGGLE_EDIT_MODE = 'plans/TOGGLE_EDIT_MODE';

// Save edit plan
export const SAVE_EDIT_REQUESTED = 'plans/SAVE_EDIT_REQUESTED';
export const SAVE_EDIT_SUCCESS = 'plans/SAVE_EDIT_SUCCESS';
export const SAVE_EDIT_FAILURE = 'plans/SAVE_EDIT_FAILURE';

// Save edit plan
export const DELETE_PLAN_REQUESTED = 'plans/DELETE_PLAN_REQUESTED';
export const DELETE_PLAN_SUCCESS = 'plans/DELETE_PLAN_SUCCESS';
export const DELETE_PLAN_FAILURE = 'plans/DELETE_PLAN_FAILURE';

// Flush the plans (clear the store)
export const FLUSH_PLANS = 'plans/FLUSH_PLANS';
