import { StoryletPhase } from 'types/storylet';

export const ACT: StoryletPhase = 'Act';
export const AVAILABLE: StoryletPhase = 'Available';
export const END: StoryletPhase = 'End';
export const EXTERNAL_ACT: StoryletPhase = 'ExternalAct';
export const IN: StoryletPhase = 'In';
export const IN_ITEM_USE: StoryletPhase = 'InItemUse';
export const RENAME: StoryletPhase = 'Rename';
export const SECOND_CHANCE: StoryletPhase = 'SecondChance';
export const UNIQUE_ACT_PENDING: StoryletPhase = 'UniqueActPending';