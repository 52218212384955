export const FETCH_REGISTER_REQUESTED = 'registration/FETCH_REGISTER_REQUESTED';
export const FETCH_REGISTER_SUCCESS = 'registration/FETCH_REGISTER_SUCCESS';
export const FETCH_REGISTER_FAILURE = 'registration/FETCH_REGISTER_FAILURE';

export const SIGNUP_REQUESTED = 'registration/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'registration/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'registration/SIGNUP_FAILURE';

export const CREATE_CHARACTER_REQUESTED = 'registration/CREATE_CHARACTER_REQUESTED';
export const CREATE_CHARACTER_SUCCESS = 'registration/CREATE_CHARACTER_SUCCESS';
export const CREATE_CHARACTER_FAILURE = 'registration/CREATE_CHARACTER_FAILURE';
