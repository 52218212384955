// Change which outfit is equipped
export const CHANGE_OUTFIT_REQUESTED = 'character/CHANGE_OUTFIT_REQUESTED';
export const CHANGE_OUTFIT_SUCCESS = 'character/CHANGE_OUTFIT_SUCCESS';
export const CHANGE_OUTFIT_FAILURE = 'character/CHANGE_OUTFIT_FAILURE';

// Change mantelpiece/scrapbook qualities
export const CHOOSE_NEW_MANTELPIECE_REQUESTED = 'myself/CHOOSE_NEW_MANTELPIECE_REQUESTED';
export const CHOOSE_NEW_MANTELPIECE_SUCCESS = 'myself/CHOOSE_NEW_MANTELPIECE_SUCCESS';
export const CHOOSE_NEW_MANTELPIECE_FAILURE = 'myself/CHOOSE_NEW_MANTELPIECE_FAILURE';
export const CHOOSE_NEW_SCRAPBOOK_REQUESTED = 'myself/CHOOSE_NEW_SCRAPBOOK_REQUESTED';
export const CHOOSE_NEW_SCRAPBOOK_SUCCESS = 'myself/CHOOSE_NEW_SCRAPBOOK_SUCCESS';
export const CHOOSE_NEW_SCRAPBOOK_FAILURE = 'myself/CHOOSE_NEW_SCRAPBOOK_FAILURE';

// Change an equipped quality
export const EQUIP_QUALITY_REQUESTED = 'character/EQUIP_QUALITY_REQUESTED';
export const EQUIP_QUALITY_SUCCESS = 'character/EQUIP_QUALITY_SUCCESS';
export const EQUIP_QUALITY_FAILURE = 'character/EQUIP_QUALITY_FAILURE';

// Fetch myself
export const FETCH_MYSELF_REQUESTED = 'myself/FETCH_MYSELF_REQUESTED';
export const FETCH_MYSELF_SUCCESS = 'myself/FETCH_MYSELF_SUCCESS';
export const FETCH_MYSELF_ERROR = 'myself/FETCH_MYSELF_ERROR';
export const FETCH_MYSELF_FAILURE = 'myself/FETCH_MYSELF_FAILURE';

// Fetch outfit
export const FETCH_OUTFIT_REQUESTED = 'character/FETCH_OUTFIT_REQUESTED';
export const FETCH_OUTFIT_SUCCESS = 'character/FETCH_OUTFIT_SUCCESS';
export const FETCH_OUTFIT_FAILURE = 'character/FETCH_OUTFIT_FAILURE';

// Fetch outfits
export const FETCH_OUTFITS_SUCCESS = 'character/FETCH_OUTFITS_SUCCESS';

// One or more qualities changed as a result of something that happened in-game
export const MYSELF_CHANGED = 'myself/MYSELF_CHANGED';

export const NEW_AVATAR_IMAGE = 'myself/NEW_AVATAR_IMAGE';

export const RENAME_OUTFIT_SUCCESS = 'myself/RENAME_OUTFIT_SUCCESS';

export const SET_CAN_CHANGE_OUTFIT = 'myself/SET_CAN_CHANGE_OUTFIT';

// Change journal (profile?) privacy
export const SET_JOURNAL_PRIVACY_REQUESTED = 'myself/SET_JOURNAL_PRIVACY_REQUESTED';
export const SET_JOURNAL_PRIVACY_SUCCESS = 'myself/SET_JOURNAL_PRIVACY_SUCCESS';
export const SET_JOURNAL_PRIVACY_FAILURE = 'myself/SET_JOURNAL_PRIVACY_FAILURE';

// A slot that contained an equipped item has become empty
export const SLOT_BECAME_EMPTY = 'character/SLOT_BECAME_EMPTY';

// Toggle between standard/expanded inventory view in Possessions tab
export const TOGGLE_EXPANDED_POSSESSIONS = 'myself/TOGGLE_EXPANDED_POSSESSIONS';

// The character name has changed
export const NAME_CHANGED = 'myself/NAME_CHANGED';