export const OPEN_DIALOG = 'payment/OPEN_DIALOG';
export const CLOSE_DIALOG = 'payment/CLOSE_DIALOG';

export const SELECT_CURRENCY_REQUESTED = 'payment/SELECT_CURRENCY_REQUESTED';
export const SELECT_CURRENCY_SUCCESS = 'payment/SELECT_CURRENCY_SUCCESS';
export const SELECT_CURRENCY_FAILURE = 'payment/SELECT_CURRENCY_FAILURE';

export const SELECT_PACKAGE = 'payment/SELECT_PACKAGE';

export const PURCHASE_REQUESTED = 'payment/PURCHASE_REQUESTED';
export const PURCHASE_SUCCESS = 'payment/PURCHASE_SUCCESS';
export const PURCHASE_FAILURE = 'payment/PURCHASE_FAILURE';

export const TOGGLE_PRICE_BREAKDOWN = 'payment/TOGGLE_PRICE_BREAKDOWN';

export const TOGGLE_PAYMENT_PROVIDER = 'payment/TOGGLE_PAYMENT_PROVIDER';
