export const FETCH_EXCHANGE_REQUESTED = 'Exchange/FETCH_EXCHANGE_REQUESTED';
export const FETCH_EXCHANGE_SUCCESS = 'exchange/FETCH_EXCHANGE_SUCCESS';
export const FETCH_EXCHANGE_FAILURE = 'exchange/FETCH_EXCHANGE_FAILURE';

export const FETCH_AVAILABLE_REQUESTED = 'exchange/FETCH_AVAILABLE_REQUESTED';
export const FETCH_AVAILABLE_SUCCESS = 'exchange/FETCH_AVAILABLE_SUCCESS';
export const FETCH_AVAILABLE_FAILURE = 'exchange/FETCH_AVAILABLE_FAILURE';

export const SELECT_STORE_REQUESTED = 'exchange/SELECT_STORE_REQUESTED';
export const SELECT_STORE_SUCCESS = 'exchange/SELECT_STORE_SUCCESS';
export const SELECT_STORE_FAILURE = 'exchange/SELECT_STORE_FAILURE';

// Open / close dialog
export const OPEN_BAZAAR_DIALOG = 'exchange/OPEN_BAZAAR_DIALOG';
export const CLOSE_BAZAAR_DIALOG = 'exchange/CLOSE_BAZAAR_DIALOG';

// sell items
export const SELL_ITEMS_REQUESTED = 'exchange/SELL_ITEMS_REQUESTED';
export const SELL_ITEMS_SUCCESS = 'exchange/SELL_ITEMS_SUCCESS';
export const SELL_ITEMS_FAILURE = 'exchange/SELL_ITEMS_FAILURE';

// buy items
export const BUY_ITEMS_REQUESTED = 'exchange/BUY_ITEMS_REQUESTED';
export const BUY_ITEMS_SUCCESS = 'exchange/BUY_ITEMS_SUCCESS';
export const BUY_ITEMS_FAILURE = 'exchange/BUY_ITEMS_FAILURE';

// reject exchange
export const REJECT_EXCHANGE = 'exchange/REJECT_EXCHANGE';
