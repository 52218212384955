// Fetch Opportunity Cards
export const BACKGROUND_FETCH_CARDS_REQUESTED = 'cards/BACKGROUND_FETCH_CARDS_REQUESTED';
export const FETCH_CARDS_REQUESTED = 'cards/FETCH_CARDS_REQUESTED';
export const FETCH_CARDS_SUCCESS = 'cards/FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_FAILURE = 'cards/FETCH_CARDS_FAILURE';

export const CARDS_SHOULD_FETCH = 'cards/CARDS_SHOULD_FETCH';

// Discard Opportunity Cards
export const DISCARD_CARDS_REQUESTED = 'cards/DISCARD_CARDS_REQUESTED';
export const DISCARD_CARDS_SUCCESS = 'cards/DISCARD_CARDS_SUCCESS';
export const DISCARD_CARDS_FAILURE = 'cards/DISCARD_CARDS_FAILURE';

// Draw Opportunity Cards
export const DRAW_CARDS_REQUESTED = 'cards/DRAW_CARDS_REQUESTED';
export const DRAW_CARDS_SUCCESS = 'cards/DRAW_CARDS_SUCCESS';
export const DRAW_CARDS_FAILURE = 'cards/DRAW_CARDS_FAILURE';

// Clear reducer state
export const CLEAR_CACHE = 'cards/CLEAR_CACHE';