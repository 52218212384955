import React from 'react';

const NotFound = () => (
  <div>
    <h1>PAGE NOT FOUND</h1>
  </div>
);

NotFound.displayName = 'NotFound';

export default NotFound;
