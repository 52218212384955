import React from 'react';

export default function MyProfile() {
  return (
    <div className="my-profile">
      <h1 className="heading heading--1">
        Me!
      </h1>
    </div>
  );
}

MyProfile.displayName = 'MyProfile';
