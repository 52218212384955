export const HIDE_MAP = 'map/HIDE_MAP';
export const TOGGLE_MAP_VIEW = 'map/TOGGLE_MAP_VIEW';

export const FALLBACK_MAP_PREFERRED = 'map/FALLBACK_MAP_PREFERRED';

export const FETCH_MAP_REQUESTED = 'map/FETCH_MAP_REQUESTED';
export const FETCH_MAP_SUCCESS = 'map/FETCH_MAP_SUCCESS';
export const FETCH_MAP_FAILURE = 'map/FETCH_MAP_FAILURE';

export const CHANGE_LOCATION_REQUESTED = 'map/CHANGE_LOCATION_REQUESTED';
export const CHANGE_LOCATION_SUCCESS = 'map/CHANGE_LOCATION_SUCCESS';
export const CHANGE_LOCATION_FAILURE = 'map/CHANGE_LOCATION_FAILURE';

export const SET_CURRENT_AREA = 'map/SET_CURRENT_AREA';
export const SET_CURRENT_SETTING = 'map/SET_CURRENT_SETTING';

export const SET_MAP_SHOULD_UPDATE = 'map/SET_MAP_SHOULD_UPDATE';
export const SET_CAN_TRAVEL = 'map/SET_ITEMS_USABLE_HERE';
